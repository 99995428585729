<template>
  <div class="main">
    <div
      data-aos="zoom-in"
      data-aos-duration="2500"
      data-aos-delay="500"
      class="mx-auto div_form"
    >
      <div class="main2">
        <div class="container" id="hubspotForm"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    formId: String,
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "8118076",
          formId: this.formId,
          target: "#hubspotForm",
          region: "na1",
        });
      }
    });
  },
};
</script>
<style scoped>
.container{
    width: 50%;
    /* height: 1500px; */
}
/* @media screen and (min-width:1904px) {

  .main,
  .main2 {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    
  }

}

@media screen and (min-width: 1264px) and (max-width: 1904px) {

  .main,
  .main2 {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {

  .main,
  .main2 {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }
} */
@media screen and (max-width: 600px) {
  .div_form {
      margin-bottom: 45%;
  }
.container{
    width: 80%;
}
}
</style>