<template>
  <div class="cont">
    <div class="container">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: #85af61;
          margin: 10% 0 5% 0;
        "
        class="divText"
      >
        <h1>DIAGNÓSTICO DE PERFIL DE INVERSIONISTA</h1>
      </div>
      <div class="container">
        <p>
          Para guiarle en el proceso de su primera inversión o inversiones ya
          realizadas, partimos del
          <strong>llenado de perfil inversionista sin datos sensibles</strong>
          que nos permitirán conocer su perfil y ofrecerle las mejores
          alternativas que se adecuen al monto de inversión deseado y las metas
          que usted considere a mediano y largo plazo.
        </p>
        <p>
          Los datos recabados son totalmente confidenciales únicamente para uso
          de consultoría personal sin fines de lucro ni transferencia a
          terceros. Para mayor información visite nuestras políticas de
          privacidad.
        </p>
      </div>
    </div>
    <Form :formId="formId" />
  </div>
</template>
<script>
import Form from "@/components/FormInversionista.vue";
export default {
  components: {
    Form,
  },
  data: () => ({
    formId: "2c6e9872-1914-4ef2-8120-072c982e9733",
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
@media screen and (min-width: 1904px) {
  .cont {
    margin-bottom: -200px;
    /* width: 100%; */
    /* height: 200%; */
    background-color: white;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .cont {
    /* margin-bottom: -300px; */
    /* width: 100%; */
    /* height: 350%; */
    background-color: white;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .cont {
    margin-bottom: 15%;
  }
}
@media screen and (max-width: 600px) {
  .divText {
    margin-top: 10%;
  }
}
.cont {
  /* margin-bottom: -300px; */
  /* width: 100%; */
  /* height: 350%; */
  background-color: white;
}
</style>